import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common'; 
import { AppComponent } from "./app.component";
import { HomeComponent } from './home/home.component';
import { WelcomeComponent } from "./welcome/welcome.component";
import { DetailsComponent } from "./details/details.component";
import { ThankyouComponent } from "./thankyou/thankyou.component";
import { AppRoutingModule } from "./app.routing.module";
import {PagenotfoundComponent} from './pagenotfound/pagenotfound.component';
import { FormsModule } from "@angular/forms";
import { VerifyComponent } from "./verify/verify.component";


@NgModule({
    declarations: [AppComponent, HomeComponent,WelcomeComponent, DetailsComponent, VerifyComponent, ThankyouComponent,PagenotfoundComponent],
    imports: [BrowserModule,HttpClientModule,CommonModule,FormsModule,AppRoutingModule],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule{}