<div *ngIf="formData && formData.or_flag == 'BillingSMS'">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="mb-3">Hi there,</h1>
        </div>
    </div>
    <form  #verifyForm="ngForm"  name="verifyFrm" (ngSubmit)="verifyPostcode(verifyForm)">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <label for="postcode" class="mb-1">So we can verify your identity, please enter the postcode for your {{formData.or_street_name}} property in the field below.</label>
                <div class="form-group mb-3">
                    <input id="postcode" ngControl="postcode" name="postcode" type="text" class="form-control col-md-6" [class.is-invalid]="postcode.invalid && (postcode.dirty || postcode.touched)" [(ngModel)]="postCode" #postcode="ngModel" required pattern="^(0[289][0-9]{2})|([1-9][0-9]{3})$">
                    <div
                    *ngIf="verifyForm.submitted || postcode.invalid && (postcode.dirty || postcode.touched)"
                    >
                    <div class="invalid-feedback d-block" *ngIf="postcode.errors?.['required']">You must supply a postcode, please try again.</div>
                    <div class="invalid-feedback d-block" *ngIf="postcode.errors?.['pattern']">
                        Your postcode must be a valid Australian postcode, please try again.
                    </div> 
                    <div class="invalid-feedback d-block" *ngIf="postcode.errors?.['incorrect']">
                        Your supplied postcode was incorrect, please try again.
                    </div>   
                    </div>
                </div>
                <div class="form-group">
                    <button id="verifybutton" name="verifybutton" type="submit" class="btn btn-primary rounded-pill">Verify</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="row" *ngIf="showError">
    <div class="col-lg-12"> &nbsp;<br>
        <div class="alert alert-danger col-sm-12 col-md-8" role="alert" [innerHTML]="error_message"></div>
    </div>
</div> 
