import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormService } from '../service/form.service';
import { reporting } from '../helpers/reporting';
import { HomeComponent } from '../home/home.component';
import { getuserId } from '../helpers/utils';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrl: './thankyou.component.css'
})
export class ThankyouComponent implements OnInit{
  applicationData: any;
  error_message!: string;
  error: any =  {};
  showError: boolean =false;
  
  constructor(private homeComp: HomeComponent, private formService:FormService, private router: Router) {
    this.formService.getAppData.subscribe(data => this.applicationData = data);
  }

  ngOnInit(): void {
    reporting();
    this.showError = false;
    this.homeComp.showErrorMsg = false;
    this.homeComp.showHeaderDetails = false;
    this.homeComp.triggerChangeDetection();
    let countdownInterval: any;
    let ttl: number;
    this.formService.checkSession(this.applicationData.formId|| getuserId()).subscribe(res => {
      ttl = res?.ttl;
      countdownInterval = setInterval(() => {
        ttl--;
        if (ttl <= 0) {
            console.log(`Session expired!`)
            clearInterval(countdownInterval);
            this.formService.endSession(this.applicationData.formId|| getuserId()).subscribe(res => {
              console.log('sessionended');
            })
        }
      }, 1000);
    }, error =>{
      this.error_message = environment.APP_MESSAGE_COOKIE_EXPIRED;
      this.showError = true;
    })
  }
  backToMainMenu () {
    this.router.navigate(['/billing'])
  }
}
