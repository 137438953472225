<div>
    <div *ngIf="formData">
        <div class="row" *ngIf="formData.or_flag == 'BillingEmail' || formData.or_flag == 'AnnualUpdate' || formData.or_flag == 'NewAccount'">
            <div class="col-lg-12">
                <h1 class="mb-3">Hi there,</h1>
            </div>
        </div>
        <form *ngIf="formData.or_flag == 'BillingEmail' || formData.or_flag == 'AnnualUpdate' || formData.or_flag == 'NewAccount'" #welcomeForm="ngForm"  name="welcomeFrm" (ngSubmit)="verifyPostcode(welcomeForm)">
            <div class="row">
                <div class="col-sm-12 col-md-8">
                    <label for="postcode" class="mb-1">So we can verify your identity, please enter the postcode for your {{formData.or_street_name}} property in the field below.</label>
                    <div class="form-group mb-3">
                        <input id="postcode" ngControl="postcode" name="postcode" type="text" class="form-control col-md-6" [class.is-invalid]="postcode.invalid && (postcode.dirty || postcode.touched)" [(ngModel)]="postCode" #postcode="ngModel" required pattern="^(0[289][0-9]{2})|([1-9][0-9]{3})$">
                        <div
                        *ngIf="welcomeForm.submitted || postcode.invalid && (postcode.dirty || postcode.touched)"
                        >
                        <div class="invalid-feedback d-block" *ngIf="postcode.errors?.['required']">You must supply a postcode, please try again.</div>
                        <div class="invalid-feedback d-block" *ngIf="postcode.errors?.['pattern']">
                            Your postcode must be a valid Australian postcode, please try again.
                        </div> 
                        <div class="invalid-feedback d-block" *ngIf="postcode.errors?.['incorrect']">
                            Your supplied postcode was incorrect, please try again.
                        </div>   
                        </div>
                    </div>
                    <p>Once we've verified this, you can view options related to your current bill.</p>
                    <div class="form-group">
                        <button id="verifybutton" name="verifybutton" type="submit" class="btn btn-primary rounded-pill">Verify</button>
                    </div>
                </div>
            </div>
        </form>
        <!-- Welcome letter 1 -->
        <div class="container-fluid bg-primary hero mb-3" *ngIf="formData.or_flag == 'BillingSMS'">
            <img src="../assets/images/boy-on-stairs.jpg" alt="Boy sits on stairs drinking water" />
            <div class="container hero-text">
                <div class="row">
                    <div class="col-lg-12">
                        <h1 class="mb-3 text-white">
                            Welcome to Urban Utilities</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="formData.or_flag == 'BillingSMS'">
            <div class="row">
                <div class="col-lg-12">
                    <p>Welcome to Urban Utilities, your local water and wastewater services provider.</p>
                    <p>We keep the water flowing and toilets flushing in more than 1.5-million properties across Brisbane, Ipswich, Lockyer Valley, Somerset and Scenic Rim.</p>
                    <p>As a provider of essential services, we make sure:</p>
                    <ul>
                        <li>You can turn on the tap at any time of the day or night for safe, high-quality drinking water.</li>
                        <li>The wastewater from your toilet, kitchen, bathroom and laundry is removed and treated safely and sustainably.</li>
                    </ul>
                    <h2>Your new account</h2>
                    <p>Purchasing a property can be an overwhelming experience, so we’ve tried to keep our part of the process simple and stress-free. <strong style="font-weight: bold">You don't need to contact us.</strong> We’re setting up an account
                        for you and services to your new property will be automatically available.</p>
                    <p> You can however, help us provide you with the best possible experience by ensuring that your contact details and billing preferences are up to date.</p>
                    <p> <button id="confirmdetailsbutton" name="confirmdetailsbutton" type="button" class="btn btn-primary rounded-pill" (click)="confirmDetails()">Confirm details</button>
                    </p>
                    <h2>Your first bill</h2>
                    <p>Depending on when the water meter at your new property was last read and your settlement date, your first bill may include some charges incurred by the previous owner. However, your solicitor or conveyancer should have accounted
                        for these charges during the settlement process. Please contact them directly if you have any questions about these charges or the settlement process.</p>
                    <h2> What does my bill pay for?</h2>
                    <p>Depending on the services available to your property, your bill pays for:</p>
                    <ul>
                        <li>24/7 access to safe, high-quality drinking water.</li>
                        <li>Collection, treatment and disposal of all the waste from your toilet and all the water that goes down your kitchen and laundry sinks, bathroom basins and showers – safely and sustainably. </li>
                        <li>Maintaining and upgrading the water and sewer networks, including pipes, pumps, reservoirs and treatment plants.</li>
                        <li>Renewing or replacing pipes as they age and repairing bursts.</li>
                        <li>New infrastructure projects to meet the needs of your community.</li>
                    </ul>
                    <h2> What now? </h2>
                    <p>Your water meter will be read approximately every 90 days. We'll then issue you a bill, which will include any applicable charges for water usage and water and wastewater services.</p>
                    <p>We recommend you check your water meter regularly (at least once a month) to keep track of your usage and detect hidden leaks – it takes minutes and can literally save you bucketloads of water, money and hassle! Visit our
                        <a href="https://urbanutilities.com.au/leaks">leaks page</a> now to learn how to perform a simple meter read self-test on your property.</p>
                    <p>If you receive a pension, you may be eligible for the State Government Pensioner Water Subsidy. You can check your eligibility and apply via the <a href="https://www.seniors-concessions.services.qld.gov.au/eligibility">Seniors Concessions website</a>,
                        or call us to discuss.</p>

                    <h2>We're here for you </h2>
                    <p> Hearing from you matters. We're here for you weekdays via <a href="https://urbanutilities.com.au/">webchat</a>, <a href="http://www.facebook.com/QueenslandUrbanUtilities">Facebook</a> or <a href="https://twitter.com/UrbanUtilities">Twitter</a>,
                            or on our General Enquiries line <a href="tel:132657">13 26 57.</a></p>

                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 pb-3">
                    <a href="https://urbanutilities.com.au/howlow">
                        <img src="../assets/images/hlcyg_banner_1200px.jpg" alt="Find out how you can reach your water saving potential. Go to our website now and take the challenge." class="img-fluid">
                    </a>
                </div>
            </div>
        </div>
        <!-- Welcome letter 2 -->
        <div class="container-fluid bg-primary hero mb-3" *ngIf="formData.or_flag == 'NotBilling'">
            <img src="../assets/images/boy-on-stairs.jpg" alt="Boy sits on stairs drinking water" />
            <div class="container hero-text">
                <div class="row">
                    <div class="col-lg-12">
                        <h1 class="mb-3 text-white">
                            Welcome!</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="formData.or_flag == 'NotBilling'">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="mb-3">Welcome to Urban Utilities – your local water and wastewater services provider</h1>
                    <p>We keep the water flowing and toilets flushing in more than 1.5-million properties across Brisbane, Ipswich, Lockyer Valley, Somerset and Scenic Rim.</p>
                    <p>As a provider of essential services, we make sure:</p>
                    <ul>
                        <li>You can turn on the tap at any time of the day or night for safe, high-quality drinking water.</li>
                        <li>The wastewater from your toilet, kitchen, bathroom and laundry is removed and treated safely and sustainably.</li>
                    </ul>
                    <h2>Your new account</h2>
                    <p>Purchasing a property can be an overwhelming experience, so we’ve tried to keep our part of the process simple and stress-free. <strong style="font-weight: bold">You don't need to contact us.</strong> We’re in the process of
                        setting up your account, however, this may take some time. Don’t worry though, any previously established services to your new property are on.</p>
                    <h2>Your first bill</h2>
                    <p>Depending on when the water meter at your new property was last read and your settlement date, your first bill may include some charges incurred by the previous owner. However, your solicitor or conveyancer should have accounted
                        for these charges during the settlement process. Please contact them directly if you have any questions about these charges or the settlement process.</p>
                    <p>Setting up a water and wastewater account is a manual and, at times, complex process. <strong style="font-weight: bold">Because of this, you may not receive your bill for some time.</strong> As a result, it may also include
                        usage and services charges for multiple billing periods.
                    </p>
                    <p>To reduce the impact of a higher than usual bill, we suggest you start setting some money aside now. An average household bill costs around $30 per week, for example. Please note this is an indicative cost only and will vary
                        depending on the size of your household and how much water you use.</p>
                    <h2> What does my bill pay for?</h2>
                    <p>Depending on the services available to your property, your bill pays for:</p>
                        
                            <ul>
                                <li>24/7 access to safe, high-quality drinking water.</li>
                                <li>Collection, treatment and disposal of all the waste from your toilet and all the water that goes down your kitchen and laundry sinks, bathroom basins and showers – safely and sustainably. </li>
                                <li>Maintaining and upgrading the water and sewer networks, including pipes, pumps, reservoirs and treatment plants.</li>
                                <li>Renewing or replacing pipes as they age and repairing bursts.</li>
                                <li>New infrastructure projects to meet the needs of your community.</li>
                            </ul>
                        
                        <h2> What now? </h2>
                        <p>Your water meter will be read approximately every 90 days. We’ll then issue you a bill, which will include any applicable charges for water usage and water and wastewater services. As we mentioned earlier, this bill may
                            not arrive for some time and be higher than usual. Please budget accordingly.</p>
                        <p>We recommend you check your water meter regularly (at least once a month) to keep track of your usage and detect hidden leaks – it takes minutes and can literally save you bucketloads of water, money and hassle! Visit our
                            <a href="https://urbanutilities.com.au/leaks">leaks page </a> now to learn how to perform a simple meter read self-test on your property.</p>
                        <p>If you receive a pension, you may be eligible for the State Government Pensioner Water Subsidy. You can check your eligibility and apply via the <a href="https://www.seniors-concessions.services.qld.gov.au/eligibility">Seniors Concessions website</a>,
                            or call us to discuss. </p>
                        <h2>We're here for you </h2>
                        <p> Hearing from you matters. We're here for you weekdays via <a href="https://urbanutilities.com.au/">webchat</a>, <a href="http://www.facebook.com/QueenslandUrbanUtilities">Facebook</a> or <a href="https://twitter.com/UrbanUtilities">Twitter</a>,
                            or on our General Enquiries line <a href="tel:132657">13 26 57.</a></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 pb-3">
                    <a href="https://urbanutilities.com.au/howlow">
                        <img src="../assets/images/hlcyg_banner_1200px.jpg" alt="Find out how you can reach your water saving potential. Go to our website now and take the challenge." class="img-fluid">
                    </a>
                </div>
            </div>
        </div>

        <!-- Welcome letter 3 -->
        <div class="container-fluid bg-primary hero mb-3" *ngIf="formData.or_flag == 'SubDivide'">
            <img src="../assets/images/boy-on-stairs.jpg" alt="Boy sits on stairs drinking water" />
            <div class="container hero-text">
                <div class="row">
                    <div class="col-lg-12">
                        <h1 class="mb-3 text-white">
                            Update</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" *ngIf="formData.or_flag == 'SubDivide'">
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="mb-3">Important information about your new accounts</h1>
                    <p>As you've recently subdivided a property, any prior billing arrangements have been put on hold while we assess the new subdivision. <strong style="font-weight: bold">You don't need to contact us.</strong> Once we have all the
                        relevant information as to the usage of the newly subdivided lots, we will set up new accounts accordingly.</p>
                    <p>Setting up water and wastewater accounts is a manual and, at times, complex process. <strong style="font-weight: bold">Because of this, you may not receive your bill for some time.</strong> As a result, it may also include
                        usage and services charges for multiple billing periods.
                    </p>
                    <p>To reduce the impact of a higher than usual bill, we suggest you start setting some money aside now. An average household bill costs around $30 per week, for example. Please note this is an indicative cost only and will vary
                        depending on the size of your household and how much water you use. </p>
                    <p>If you receive a pension, you may be eligible for the State Government Pensioner Water Subsidy. You can check your eligibility and apply via the <a href="https://www.seniors-concessions.services.qld.gov.au/eligibility">Seniors Concessions website</a>,
                        or call us to discuss. </p>
                    <h2>What does my bill pay for?</h2>
                    <p> Depending on the services available to your property, your bill pays for:</p>
                    <ul>
                        <li>24/7 access to safe, high-quality drinking water.</li>
                        <li>Collection, treatment and disposal of all the waste from your toilet and all the water that goes down your kitchen and laundry sinks, bathroom basins and showers – safely and sustainably.</li>
                        <li>Maintaining and upgrading the water and sewer networks, including pipes, pumps, reservoirs and treatment plants.</li>
                        <li>Renewing or replacing pipes as they age and repairing bursts.</li>
                        <li>New infrastructure projects to meet the needs of your community.</li>
                    </ul>
                    <h2>We're here for you </h2>
                    <p> Hearing from you matters. We're here for you weekdays via <a href="https://urbanutilities.com.au/">webchat</a>, <a href="http://www.facebook.com/QueenslandUrbanUtilities">Facebook</a> or <a href="https://twitter.com/UrbanUtilities">Twitter</a>,
                        or on our General Enquiries line <a href="tel:132657">13 26 57.</a></p>
                </div>
                <div class="row">
                    <div class="col-sm-12 pb-3">
                        <a href="https://urbanutilities.com.au/howlow">
                            <img src="../assets/images/hlcyg_banner_1200px.jpg" alt="Find out how you can reach your water saving potential. Go to our website now and take the challenge." class="img-fluid">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-primary" *ngIf="formData.or_flag == 'NotBilling'|| formData.or_flag == 'SubDivide'|| formData.or_flag == 'BillingSMS'">
            <div class="container">
                <div class="row" style="color: #fff; font-size: .8em">
                    <div class="col-sm-6 pt-3">
                        <a href="https://urbanutilities.com.au/"><img src="../assets/images/QUU-logo-white.png" alt="Urban Utilities logo" /></a>
                    </div>
                    <div class="col-sm-6 pt-3">
                        <div class="float-md-right">
                            <a href="https://twitter.com/#!/UrbanUtilities"><img class="pr-3" src="../assets/images/QUU-twitter.png" aria-label="Twitter" /></a>
                            <a href="http://www.facebook.com/QueenslandUrbanUtilities"><img class="pr-3" src="../assets/images/QUU-facebook.png" aria-label="Facebook" /></a>
                            <a href="http://www.youtube.com/user/urbanutilities"><img class="pr-3" src="../assets/images/QUU-youtube.png" aria-label="Youtube" /></a>
                            <a href="http://www.linkedin.com/company/queensland-urban-utilities"><img src="../assets/images/QUU-linkedin.png" aria-label="Linkedin" /></a>
                        </div>
                    </div>
                </div>
                <div class="row" style="color: #fff; font-size: .8em">
                    <div class="col-lg-12 pt-3">
                        <p>Urban Utilities provides water and sewerage services to customers in Brisbane, Ipswich, Lockyer Valley, Somerset and Scenic Rim. Urban Utilities is the trading name of the Central SEQ Distributor-Retailer Authority.</p>
                        <p>You have received this communication because you are a customer of ours and we have your communication details in our records. You can change the way we send you notifications via <a style="color: #fff !important; border-bottom-color:#fff !important" href="https://urbanutilities.com.au/login">MyAccount</a> or by calling <a style="color: #fff !important; border-bottom-color:#fff !important" href="tel:132657">13 26 57</a>.</p>
                        <p>This communication is intended for the addressee only and may contain information that is private and confidential, legally privileged and subject to copyright. If you are not the intended recipient, please immediately
                            notify us and destroy the communication. Urban Utilities does not warrant the integrity or security of this communication, or accept liability for any loss or damage arising from the transmission of this communication.
                        </p>
                        <p><strong style="font-weight: bold">Privacy and why we collect your information:</strong> Your privacy is important to us. We collect contact details for new customers from the Queensland Titles Registry following the transfer of property in our service area. A Form 24 is completed and lodged with the Registry during property settlement and personal information is transferred to us under section 99AX(4) of the South-East Queensland Water (Distribution and Retail Restructuring) Act 2009 (Qld). Your personal information is collected to send you billing correspondence and to make other contact with you about your account; to update our customer and billing records and verify your identity in future; to contact you about network faults and emergencies and manage the water and sewerage services we provide; and to conduct customer research. Urban Utilities may use and/or disclose your personal information to its employees, agents or contractors for these purposes and to other third parties as otherwise required or authorised by law. 
                            For more on how we protect the personal information we collect, and to find out how to make a privacy complaint, access our Privacy Policy at  <a style="color: #fff !important; border-bottom-color:#fff !important" href="https://www.urbanutilities.com.au/privacy">www.urbanutilities.com.au/privacy</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid" style="background-color: #3d4248" *ngIf="formData.or_flag == 'NotBilling'|| formData.or_flag == 'SubDivide'|| formData.or_flag == 'BillingSMS'">
            <div class="container">
                <div class="row" style="color: #fff; font-size: .8em">
                    <div class="col-lg-12 mt-3">
                        <p>© 2020 Urban Utilities.<br>ABN 86 673 835 011</p>
                    </div>
                </div>
            </div>
        </div>
    </div>   
    <div class="row" *ngIf="showError">
        <div class="col-lg-12"> &nbsp;<br>
            <div class="alert alert-danger col-sm-12 col-md-8" role="alert" [innerHTML]="error_message"></div>
        </div>
    </div> 
</div>
