

<div class="row on-error page-active" *ngIf ="showError">
    <div class="col-lg-12"> &nbsp;<br>
        <div class="alert alert-danger col-md-12 col-lg-6" role="alert" [innerHTML]="error_message"></div>
    </div>
</div>

<div *ngIf="!showError">
    <div class="row">
        <div class="col-sm-12 mb-3">
            <h2 class="mb-3">Thank you</h2>
            <div class="alert alert-dark text-white p-4 col-sm-12 col-md-6 col-lg-4" role="alert">
                <i class="fas fa-check-circle"></i> Your details have been saved.
            </div>
            <p>Thanks for confirming your contact details and preferences. </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 mb-3">
            <h4>Any questions? We're here to help. </h4>
                <p>Find the answer you’re looking for and other useful information in our <a target="_blank" href="https://urbanutilities.com.au/contact-us/faqs" class="click-me" id="faq-link">FAQs</a>. We’re also here for you 
                    via <a target="_blank" href="https://urbanutilities.com.au/contact-us" class="click-me" id="webchat-link"> webchat</a> or our General Enquiries line
                    <a href="tel:132657" class="click-me" id="phone-link">13&nbsp;26&nbsp;57</a>.
                </p>
        </div>
    </div>
</div>