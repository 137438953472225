import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import {isIE} from '../helpers/utils';
import { FormService } from '../service/form.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {
  error_message!: string;
  showIEErrorMsg: boolean =  false;
  hardstop:  boolean = false;
  showErrorMsg: boolean = false;
  appData: any;
  showHeaderDetails: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef,private formService:FormService) {
    
  }
 
  triggerChangeDetection() {
    this.changeDetectorRef.detectChanges();
  }
  ngOnInit(): void {
    if (isIE()) {
      console.log('Internet Explorer detected'); // Show errror message
      this.showIEErrorMsg = true;
      this.hardstop = true;
  } else if(!navigator.cookieEnabled) {
    this.error_message = environment.APP_MESSAGE_COOKIE_DISABLED;
    this.hardstop = true;
  } else {
    this.showErrorMsg = true;
    this.error_message = environment.APP_MESSAGE_NO_FOMID;
    this.formService.getAppData.subscribe(data =>{
      this.appData = data;
      this.showHeaderDetails = true;
    })
  }
  }


  title = 'default';
  clientId: string = environment.APP_CLIENTID
}
