export const environment = {
    production: false,
    APP_CLIENTID: 'quuwcuat',
    APP_TEMPLATEID: 'quuwcuat_template',
    APP_ORIGINATINGAPP: 'quuwcuat',
    APP_COOKIE_EXPIRY_MINUTES: 60,
    APP_OTP_EXPIRY_MINUTES:  3,
    DOMAIN: 'ebuat.urbanutilities.com.au',
    TITLE: 'QUU - Smart eBilling',
    TITLE_SEPERATOR: '-',
    DESCRIPTION: 'QUU - Smart eBilling',
    APP_GOOGLEID:'',
    SITE_BASE_URL: 'https://ccuat.urbanutilities.com.au',
    GOOGLEUA: '',
    VIDEO_LINK: 'https://youtube.com/embed/24HnxRZNAcQ',
    APP_MESSAGE_BAD_REQUEST: "Please retry the link you initially received.",
    APP_MESSAGE_NO_FOMID: "The URL supplied is incorrect. <br> Please try the link you received again, or try again at a later time. ",
    APP_MESSAGE_FORM_EXPIRED    : "This item has expired or is no longer available.",
    APP_MESSAGE_NOT_FOUND       : "An error occurred while retrieving your information. Please try the link you received again, or try again at a later time.",
    APP_MESSAGE_FAILED          : "Your one time code is not valid, ensure to use the most recent one time code to continue.",
    APP_MESSAGE_FORM_UNAUTHORIZED : "Your one time code is not valid, ensure to use the correct value to continue.",
    APP_MESSAGE_EXPIRED_CODE    : "Your one time code has expired, request a new one time code and ensure to use the most recent one time code to continue.",
    APP_MESSAGE_FORM_LOCKUP     : "You have exceeded all attempts for today. Your access has been temporarily disabled. Please retry the link you initially received in 24hrs.",
    APP_MESSAGE_FORBIDDEN       : "Please retry the link you initially received.",
    APP_MESSAGE_NO_ID           : "Please retry the link you initially received without modifcation.",
    APP_MESSAGE_COOKIE_EXPIRED  : "Your session has timed out. Please retry the link you initially received without modifcation.",
    APP_MESSAGE_DEFAULT         : "We're sorry, but there seems to be a technical issue.",
    APP_MESSAGE_COOKIE_DISABLED : "This site requires cookies to be enabled. Please enable cookies in your browser settings and revisit the link you received.",
    APP_MESSAGE_API_FAILURE: "An error occurred while retrieving your information.<br> Please try the link you received again, or try again at a later time."
};